
<template>
  <div>
    <!-- Filters -->
    <!-- <transfers-list-filters
      v-if="balanceType"
      :reference-filter.sync="referenceFilter"
      :ref-number-filter.sync="refNumberFilter"
      :partner-ref-number-filter.sync="partnerRefNumberFilter"
      :username-filter.sync="usernameFilter"
      :fromdate-filter.sync="fromdateFilter"
      :todate-filter.sync="todateFilter"
      :bonus-code-filter.sync="bonusCodeFilter"
      :status-filter.sync="statusFilter"
      :balance-type-from-filter.sync="balanceTypeFromFilter"
      :balance-type-to-filter.sync="balanceTypeToFilter"
      :status-options="statusOptions"
      :balance-type="balanceType"
    /> -->
    <div class="p-1">
      <b-row>
        <b-col
          v-if="$can('create', 'adjustbalance')"
          class="col d-flex justify-content-end"
        >
          <b-button variant="primary" @click="addAdjustmentReason">
            <feather-icon icon="PlusIcon" size="16" /> {{ $t("New") }}
          </b-button>
        </b-col>
      </b-row>
    </div>

    <b-card no-body class="mb-0">
      <b-table
        ref="refAdjustmentReasonListTable"
        class="position-relative table-white-space max-height-table"
        sticky-header
        head-variant="light"
        :no-border-collapse="true"
        :items="fetchAdjustmentReason"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(index)="data">
          {{  data.index + 1 }}
        </template>

        <template #cell(name)="data">
          <b-link @click="updateAdjustmentReason(data.item)">
            {{ data.item.name }}
          </b-link>
        </template>

        <template #cell(created_by)="data">
          <b-link>
            {{ data.item.created_by }}
          </b-link>
        </template>

        <template #cell(is_bonus)="data">
          {{ data.item.is_bonus == 1 ? 'Có' : 'Không'}}
        </template>

        <template #cell(created_at)="data">
          {{ formatDateTime(data.item.created_at).replace(".000000Z", "") }}
        </template>

        <template #cell(updated_at)="data">
          {{ formatDateTime(data.item.updated_at).replace(".000000Z", "") }}
        </template>

        <!-- Column: Actions -->
        <template #cell(action)="data">
          <b-button
            v-b-tooltip.hover.v-info
            :title="$t('Edit')"
            v-if="$can('update', 'adjustbalancestatus')"
            variant="primary"
            class="btn-icon btn-sm mr-50"
            @click="updateAdjustmentReason(data.item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.v-danger
            :title="$t('Reject')"
            v-if="$can('view', 'adjustbalance')"
            variant="danger"
            class="btn-icon btn-sm"
            @click="deleteAdjustmentReason(data.item.id)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalAdjustmentReason"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!-- Transfer Detail Modal
    <transfer-detail-modal
      :transfer-data.sync="transferData"
    /> -->
    <!-- Add adjustmentReason Modal -->
    <AddAdjustmentReasonModal @refetch-data="refetchData" />

    <!-- Update adjustmentReason Modal -->
    <update-adjustment-reason-modal
      :adjustment-reason-detail.sync="adjustmentReasonDetail"
      :adjustmentReasonDetail="adjustmentReasonDetail"
      @refetch-data="refetchData"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BLink,
  BPagination, VBTooltip,
} from "bootstrap-vue";
import { onUnmounted } from "@vue/composition-api";
import {
  avatarText,
  numberFormat,
  resolveCurrency,
  formatDateTime,
} from "@core/utils/filter";
import store from "@/store";
// import TransfersListFilters from './TransfersListFilters.vue'
import useAdjustmentReason from "./useAdjustmentReason";
import adjustmentReasonStoreModule from "./adjustmentReasonStoreModule";

export default {
  components: {
    // TransfersListFilters,
    AddAdjustmentReasonModal: () => import("./AddAdjustmentReasonModal.vue"),
    UpdateAdjustmentReasonModal: () => import("./UpdateAdjustmentReasonModal.vue"),
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BLink,
    BPagination,
  },
  data() {
    return {
     adjustmentReasonDetail: null,
      // type: null,
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  methods: {
        addAdjustmentReason () {
            this.$bvModal.show('modal-add-adjustment-reason')
        },
        deleteAdjustmentReason (adjustmentReasonId) {
            this.$swal({
				title: 'Are you sure ?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				customClass: {
					confirmButton: 'btn btn-primary',
					cancelButton: 'btn btn-outline-danger ml-1',
				},
				buttonsStyling: false,
			}).then(result => {
				if (result.value) {
					const adjustmentReasonData = {
						id: adjustmentReasonId,
					}
					store.dispatch('reason/deleteAdjustmentReason', adjustmentReasonData)
						.then(response => {
							if (response.data.code == '00') {
								this.$swal({
									icon: 'success',
									title: 'Deleted!',
									text: 'Your item has been deleted.',
									customClass: {
										confirmButton: 'btn btn-success',
									},
								})
								this.refetchData()
							}
						})
				}
			})
        },

		updateAdjustmentReason (datas) {
			this.adjustmentReasonDetail = datas;
			this.$bvModal.show('modal-update-adjustment-reason')
		}
    },
  setup() {
    if (!store.hasModule("reason"))
      store.registerModule("reason", adjustmentReasonStoreModule);
    onUnmounted(() => {
      if (store.hasModule("reason")) store.unregisterModule("reason");
    });

    const {
      fetchAdjustmentReason,
      tableColumns,
      perPage,
      currentPage,
      totalAdjustmentReason,
      dataMeta,
      refAdjustmentReasonListTable,
      refetchData,

      // Extra Filters
      idFilter,
    } = useAdjustmentReason();

    return {
      fetchAdjustmentReason,
      tableColumns,
      perPage,
      currentPage,
      totalAdjustmentReason,
      dataMeta,
      refAdjustmentReasonListTable,
      refetchData,

      // Filter
      avatarText,
      numberFormat,
      formatDateTime,
      resolveCurrency,

      idFilter,
    };
  },
};
</script>
<style scoped>
.max-height-table {
  max-height: 580px;
}
</style>