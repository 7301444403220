import ReasonService from "@/libs/reason.service";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAdjustmentReason(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        ReasonService.getAdjustmentReasonLists(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addAdjustmentReason(ctx, adjustmentReasonData) {
      return new Promise((resolve, reject) => {
        ReasonService.addAdjustmentReason(adjustmentReasonData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateAdjustmentReason(ctx, adjustmentReasonData) {
      return new Promise((resolve, reject) => {
        ReasonService.updateAdjustmentReason(adjustmentReasonData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteAdjustmentReason(ctx, adjustmentReasonData) {
      return new Promise((resolve, reject) => {
        ReasonService.deleteAdjustmentReason(adjustmentReasonData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
