import { ref, watch, computed } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import i18n from "@/libs/i18n";

export default function useAdjustmentReasonList() {
  // Use toast
  const toast = useToast();

  const refAdjustmentReasonListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "index", label: "#" },
    { key: "name", label: "Name" },
    { key: "is_bonus", label: "Is bonus" },
    // { key: 'adjustment_reason_name', label: i18n.t('Adjustment reason name') },
    // { key: 'code', label: i18n.t('Code') },
    // { key: 'is_change_to_affiliate', label: i18n.t('Is change to affiliate') },
    // { key: 'description', label: i18n.t('Description') },
    { key: "created_by", label: i18n.t("Created by") },
    { key: "created_at", label: i18n.t("Created at") },
    { key: "updated_at", label: i18n.t("Updated at") },
    { key: "action", label: i18n.t("Actions") },
  ];
  const perPage = ref(25);
  const totalAdjustmentReason = ref(0);
  const currentPage = ref(1);
  const idFilter = ref(null);

  const refetchData = () => {
    refAdjustmentReasonListTable.value.refresh();
  };

  watch([currentPage], () => {
    refetchData();
  });

  const fetchAdjustmentReason = (ctx, callback) => {
    store
      .dispatch("reason/fetchAdjustmentReason", {
        page: currentPage.value,
        id: idFilter.value,
      })
      .then((response) => {
        callback(response.data.data);
        totalAdjustmentReason.value = response.data.count;
        perPage.value = currentPage == 1 ? response.data.data.length : 25;
      })
      .catch((error) => {
        const { response } = error;
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message || "Error fetching transfers list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const dataMeta = computed(() => {
    const localItemsCount = refAdjustmentReasonListTable.value
      ? refAdjustmentReasonListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalAdjustmentReason.value,
    };
  });

  return {
    fetchAdjustmentReason,
    tableColumns,
    perPage,
    currentPage,
    totalAdjustmentReason,
    dataMeta,
    refAdjustmentReasonListTable,
    refetchData,
    idFilter,
  };
}
